
import { computed, defineComponent, ref } from 'vue'
import { useStoriesStore } from '../../store/stores/stories/stories'
import storiesList from '../../store/stores/stories/storiesList'
import * as _ from 'lodash'
import StoriesModal from './StoriesModal/StoriesModal.vue'
import { openInNewTab } from '../../utils'
export default defineComponent({
    components: { StoriesModal },
    props: {
        id: { type: String, required: true },
        skeleton: { type: Boolean },
    },
    setup(props, { emit }) {
        const stories = computed(() => _.get(storiesList, props.id))
        const modalIsOpen = ref(false)
        const styles = computed(() => {
            if (props.skeleton)
                return {
                    backgroundColor: `#ddd`,
                    boxShadow: ``,
                }
            return {
                background: `radial-gradient(ellipse at left top, ${stories.value.colors.gradient1} 20%,${stories.value.colors.gradient2})`,
                boxShadow: ` 0px 20px 20px ${stories.value.colors.shadow}`,
            }
        })
        const openModal = () => {
            modalIsOpen.value = true
        }
        const onClick = () => {
            if(props.id === "sightseeing-tour") {
                emit('show-video', true)
                return
            } else if (stories.value.hrefTo) {
                return openInNewTab(stories.value.hrefTo)
            }
            openModal()
        }

        return { onClick, stories, styles, modalIsOpen }
    },
})
