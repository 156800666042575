
import { defineComponent, ref, computed, onMounted, getCurrentInstance } from 'vue'
import StoriesCard from './StoriesCard.vue'
import { useStoriesStore } from '../../store/stores/stories/stories'
import useRouter from '../../hooks/useRouter'
export default defineComponent({
    props: {},
    components: { StoriesCard },
    setup(props, { emit }) {
        const root = getCurrentInstance()!.proxy

        const { route } = useRouter(root)
        const storiesStore = useStoriesStore()
        const projectId = computed(() => route.value.params.id)
        const loading = ref(true)
        const setVideoHelepVisible = (value) => {
            emit('show-video', value)
        }

        const stories = computed(() => {
            if (loading.value) return [{ id: '1' }, { id: '2' }, { id: '3' }]
            return storiesStore.getProjectStories(projectId.value)
        })
        const currentSlide = ref(0)

        const processedSlides = computed(() => {
            if (loading.value) return [[{}, {}, {}]];
            
            const slides = [];
            const total = stories.value.length;
            
            for (let i = -1; i < total; i++) {
                const slideItems = [];
                for (let j = 0; j < 3; j++) {
                    const index = (i + j) % total;
                    slideItems.push(
                        stories.value[index >= 0 ? index : total + index]
                    );
                }
                if (i >= 0) slides.push(slideItems);
            }
            
            return slides.length > 0 ? slides : [[{}, {}, {}]];
        })
        onMounted(async () => {
            loading.value = true
            await storiesStore.fetchProjectStories(projectId.value)
            loading.value = false
        })
        return {
            stories,
            loading,
            setVideoHelepVisible,
            currentSlide,
            processedSlides,
        }
    },
})
